<template>
	 <main>
        <HeaderTab :title="$t('monte.planning')" :swiper_tabs="swiper_tabs" />

        <div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <keep-alive>
                            <router-view></router-view>
                        </keep-alive>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script type="text/javascript">
    import Navigation from "@/mixins/Navigation.js";

	export default {
		name: 'PlanningMonteBeta',
		mixins: [Navigation],
		data () {
			return {
				param_tab: '',
                actual_tab: 'planningMain',
                default_tab: 'planningMain',
                tabsToReload: [],
			}
		},

		mounted() {
			// this.param_tab = this.$route.params.actual_tab
		},

		methods: {
            updateActualTab(href) {
                this.actual_tab = href
                if(this.$route.name == 'PlanningMonteBeta')
                    this.$router.replace({ name: this.actual_tab })
            }
		},

		computed:{
            swiper_tabs() {
                const config = this.getConfig('tabs_config')
                const config_tabs = config['planning_monte'][1].length > 0 ? config['planning_monte'][1][0].tabs : config['planning_monte'][0][0].tabs

                let tabs = [{
                        id: 'planningMain',
                        label: 'monte.planning_monte',
                        active: false,
                        href: 'planningMainBeta'
                    },
                    {
                        id: 'planningMonte',
                        label: 'monte.planning_ia',
                        active: false,
                        href: 'planningIABeta',
                    },
                ]

                let res_tab = config_tabs.map(tab => tabs.find(t => t.href == tab))

                res_tab.push({
                    label: '',
                    icon: ['fal', 'cogs'],
                    href : 'tabConfig',
                    params: {type: 'planning_monte', from: this.$route.path},
                    active: false
                })

                res_tab[0].active = true

                this.updateActualTab(res_tab[0].href)

                return res_tab
            },
        },

		components: {
            HeaderTab: () => import('@/components/HeaderTab'),
		}
	}

</script>